<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingFeatDetails"></b-loading>

    <div v-if="selectedFeat">
      <h4 class="title is-4">
        {{ selectedFeat.name }}<br />
        <small>Dates: {{ selectedFeat.date_start }} - {{ selectedFeat.date_end }}</small>
      </h4>
      <h6 class="title is-6">Epic: <router-link :to="`/epics/${selectedFeat.epic.id}`">{{ selectedFeat.epic.name }}</router-link></h6>
      <div class="columns mb-6 message">
        <div class="column is-6">
          <h6 class="title is-6">Minimum Goal: {{ selectedFeat.goal_minimum }}</h6>
          <h6 class="title is-6">Bonus Goal: {{ selectedFeat.goal_bonus }}</h6>
          <h6 class="title is-6">Super Mega Goal: {{ selectedFeat.goal_supermega }}</h6>
        </div>
        <div class="column is-6">
          <h6 class="title is-6">Total Participants: {{ selectedFeat.users_count }}</h6>
          <h6 class="title is-6">Total Contribution: {{ selectedFeat.total_contribution }}</h6>
        </div>
      </div>

      <h6 class="title is-6">Goal Description (for game)</h6>
      <p class="mb-6">{{ selectedFeat.goal_description }}</p>

      <h6 class="title is-6">Description</h6>
      <p class="mb-6" v-html="$options.filters.nl2br(selectedFeat.description)" />

      <h6 class="title is-6">Legend</h6>
      <p class="mb-6" v-html="$options.filters.nl2br(selectedFeat.legend)" />

      <b-tabs type="is-boxed" expanded>
        <b-tab-item label="Details">
          <feat-form :feat="selectedFeat"></feat-form>
        </b-tab-item>
        <b-tab-item label="Participants">
          <feat-users :users="selectedFeat.users"></feat-users>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import FeatForm from '../../components/Feats/FeatForm.vue';
import FeatUsers from '../../components/Feats/FeatUsers.vue';

export default {
  name: 'FeatDetails',
  components: {
    FeatUsers,
    FeatForm,
  },
  props: {
    feat: String,
  },
  computed: {
    ...mapState('feats', ['loadingFeatDetails', 'selectedFeat']),
  },
  mounted() {
    this.loadFeat(this.feat);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadFeat(to.params.feat);
    next();
  },
  methods: {
    loadFeat(id) {
      this.$store.dispatch('feats/loadFeatDetails', id);
    },
  },
};
</script>

<style lang="scss">
.stat-list > div {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .user-stat-icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}

</style>
