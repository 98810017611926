<template>
  <div>
    <h4 class="title has-text-centered is-4">{{ feat ? 'Feat Details' : 'New Feat' }}</h4>

    <form @submit="handleSubmit" v-if="epicsData">
      <div class="columns">
        <div class="column is-4">
          <b-field label="Name" custom-class="is-small">
            <b-input v-model="formData.name" size="is-small"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Epic" custom-class="is-small">
            <b-select v-model="formData.epic_id" size="is-small">
              <option></option>
              <option
                v-for="epicItem in epicsData"
                :key="epicItem.id"
                :value="epicItem.id"
              >
                {{ epicItem.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Action" custom-class="is-small">
            <b-input v-model="formData.action" size="is-small"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-6">
          <b-field label="Start Datetime (Y-m-d H:i:s)" custom-class="is-small">
            <b-input v-model="formData.date_start" size="is-small"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="End Datetime (Y-m-d H:i:s)" custom-class="is-small">
            <b-input v-model="formData.date_end" size="is-small"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-6">
          <b-field label="Goal Description (For game)" custom-class="is-small">
            <b-input v-model="formData.goal_description" type="textarea" size="is-small"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Goal Accomplished Text" custom-class="is-small">
            <b-input v-model="formData.goal_accomplished_text" size="is-small"></b-input>
          </b-field>

          <b-field label="Goal Item" custom-class="is-small">
            <b-input v-model="formData.goal_item" size="is-small"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-6">
          <b-field label="Description" custom-class="is-small">
            <b-input v-model="formData.description" type="textarea" size="is-small"></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Legend" custom-class="is-small">
            <b-input v-model="formData.legend" type="textarea" size="is-small"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4">
          <b-field label="Minimum Goal" custom-class="is-small">
            <b-input v-model="formData.goal_minimum" size="is-small"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Bonus Goal" custom-class="is-small">
            <b-input v-model="formData.goal_bonus" size="is-small"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Super Mega Goal" custom-class="is-small">
            <b-input v-model="formData.goal_supermega" size="is-small"></b-input>
          </b-field>
        </div>
      </div>

      <b-button type="is-success" native-type="submit">{{ feat ? 'Update' : 'Create' }}</b-button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Feat } from '@/services/api';

export default {
  name: 'feat-form',
  props: {
    feat: Object,
    epic: Object,
  },
  watch: {
    feat: {
      immediate: true,
      handler(val) {
        if (val) {
          this.formData = {
            ...val,
          };
        }
      },
    },
  },
  computed: {
    ...mapState('feats', ['loadingEpics', 'epicsData']),
  },
  data() {
    return {
      errors: [],
      formData: {
        name: null,
        action: null,
        epic_id: null,
        date_start: null,
        date_end: null,
        description: null,
        legend: null,
        goal_minimum: null,
        goal_bonus: null,
        goal_supermega: null,
        goal_description: null,
        goal_accomplished_text: null,
        goal_item: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  mounted() {
    if (!this.epicsData.length) {
      this.loadEpics();
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const action = this.feat ? Feat.updateFeat(this.feat.id, this.formData) : Feat.createFeat(this.formData.epic_id, this.formData);

      action
        .then(() => {
          this.sent = true;
          if (this.epic) {
            this.refreshEpic();
          } else if (this.feat) {
            this.refreshFeat();
          } else {
            this.refreshFeats();
          }
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    loadEpics() {
      this.$store.dispatch('feats/loadEpics');
    },
    refreshEpic() {
      this.$store.dispatch('feats/loadEpicDetails', this.epic.id);
    },
    refreshFeat() {
      this.$store.dispatch('feats/loadFeatDetails', this.feat.id);
    },
    refreshFeats() {
      this.$store.dispatch('feats/loadFeats');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
