<template>
  <div>
    <b-table
      :data="users"
      :striped="true"
      :hoverable="true"
      :loading="loadingFeatDetails"
      class="mt-4 clickable-table user-table"
      @click="goToUser"
    >
      <template v-slot:empty>
        <div class="has-text-centered">
          <h3>No participants yet...</h3>
        </div>
      </template>

      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column field="name" label="Name" v-slot="props">
        <p>
          <strong>{{ props.row.name }}</strong>
          <br />
          {{ props.row.email }}
        </p>
      </b-table-column>

      <b-table-column field="date_joined" label="Joined" v-slot="props">
        <p>
          {{ props.row.pivot.date_joined | dateStandard }}
        </p>
      </b-table-column>

      <b-table-column field="contribution" label="Contribution" v-slot="props">
        <p>
          {{ props.row.pivot.contribution }}
        </p>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'feat-users',
  props: {
    users: Array,
  },
  computed: {
    ...mapState('feats', ['loadingFeatDetails', 'selectedFeat']),
  },
  methods: {
    goToUser(row) {
      this.$router.push({ path: `/users/${row.id}` });
    },
  },
};

</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
